import { Attribute } from 'keycloakify/login/kcContext/KcContext';

export const profile: {
  attributes: Attribute[];
  attributesByName: Record<string, Attribute>;
} = {
  attributesByName: {
    date: {
      validators: { 'local-date': { 'ignore.empty.value': true }, 'up-immutable-attribute': {} },
      displayName: 'Date with validator',
      annotations: {},
      required: false,
      groupAnnotations: {},
      readOnly: false,
      name: 'date',
    },
    lastName: {
      validators: {
        length: { max: '255', 'ignore.empty.value': true },
        'person-name-prohibited-characters': { 'ignore.empty.value': true },
        'up-immutable-attribute': {},
        'up-attribute-required-by-metadata-value': {},
      },
      displayName: '${lastName}',
      annotations: {},
      required: true,
      value: 'Test',
      groupAnnotations: {},
      readOnly: false,
      name: 'lastName',
    },
    adasda: {
      validators: { uri: { 'ignore.empty.value': true }, 'up-immutable-attribute': {} },
      displayName: 'URL input with validator',
      annotations: {},
      required: false,
      groupAnnotations: {},
      readOnly: false,
      name: 'adasda',
    },
    num: {
      validators: { double: { 'ignore.empty.value': true, min: '1', max: '10' }, 'up-immutable-attribute': {} },
      displayName: 'Double number',
      annotations: {},
      required: false,
      groupAnnotations: {},
      readOnly: false,
      name: 'num',
    },
    length: {
      validators: { length: { 'ignore.empty.value': true, min: '10', max: '20' }, 'up-immutable-attribute': {} },
      displayName: 'Min Length validator',
      annotations: {},
      required: false,
      groupAnnotations: {},
      readOnly: false,
      name: 'length',
    },
    pattern: {
      validators: {
        pattern: { pattern: 'asd', 'ignore.empty.value': true, 'error-message': 'error-pattern-no-match' },
        'up-immutable-attribute': {},
      },
      displayName: 'Pattern match',
      annotations: {},
      required: false,
      groupAnnotations: {},
      readOnly: false,
      name: 'pattern',
    },
    usrname2: {
      validators: {
        'username-prohibited-characters': {
          'error-message': 'error-username-invalid-character',
          'ignore.empty.value': true,
        },
        'up-immutable-attribute': {},
      },
      displayName: 'Username with validator',
      annotations: {},
      required: false,
      groupAnnotations: {},
      readOnly: false,
      name: 'usrname2',
    },
    int: {
      validators: { integer: { 'ignore.empty.value': true, min: '1', max: '10' }, 'up-immutable-attribute': {} },
      displayName: 'Integer ',
      annotations: {},
      required: false,
      groupAnnotations: {},
      readOnly: false,
      name: 'int',
    },
    firstName: {
      validators: {
        length: { max: '255', 'ignore.empty.value': true },
        'person-name-prohibited-characters': { 'ignore.empty.value': true },
        'up-immutable-attribute': {},
        'up-attribute-required-by-metadata-value': {},
      },
      displayName: '${firstName}',
      annotations: {},
      required: true,
      value: 'Josh',
      groupAnnotations: {},
      readOnly: false,
      name: 'firstName',
    },
    testList: {
      validators: {
        options: { options: ['Value 1', 'Value 2', 'Value 3', 'Value 4'] },
        'up-immutable-attribute': {},
        'up-attribute-required-by-metadata-value': {},
      },
      displayName: 'Test List',
      annotations: { inputType: 'select', inputHelperTextBefore: 'Placeholder?' },
      required: true,
      value: 'Value 2',
      group: 'UMD',
      groupAnnotations: { whats: 'zhis' },
      readOnly: false,
      groupDisplayDescription: 'User metadata',
      name: 'testList',
      groupDisplayHeader: 'UMD',
    },
    testTextField: {
      validators: {
        length: { 'ignore.empty.value': true, min: '5', max: '255' },
        'up-immutable-attribute': {},
        'up-attribute-required-by-metadata-value': {},
      },
      displayName: 'Test Text Field',
      annotations: { inputHelperTextBefore: 'Whats this' },
      required: true,
      value: 'wefwwef',
      group: 'UMD',
      groupAnnotations: { whats: 'zhis' },
      readOnly: false,
      groupDisplayDescription: 'User metadata',
      name: 'testTextField',
      groupDisplayHeader: 'UMD',
    },
    person: {
      validators: {
        'person-name-prohibited-characters': {
          'error-message': 'error-person-name-invalid-character',
          'ignore.empty.value': true,
        },
        'up-immutable-attribute': {},
      },
      displayName: 'Person name with validator',
      annotations: {},
      required: false,
      groupAnnotations: {},
      readOnly: false,
      name: 'person',
    },
    email: {
      validators: {
        'up-blank-attribute-value': { 'error-message': 'missingEmailMessage', 'fail-on-null': false },
        'up-email-exists-as-username': {},
        length: { max: '255', 'ignore.empty.value': true },
        'up-immutable-attribute': {},
        'up-duplicate-email': {},
        'up-attribute-required-by-metadata-value': {},
        email: { 'ignore.empty.value': true },
        pattern: {
          'ignore.empty.value': true,
          // @ts-expect-error if this isn't undefined, the patter becomes gmail.com coming from keycloakify's kcContextMocks.ts :/
          pattern: undefined,
        },
      },
      displayName: '${email}',
      annotations: {},
      required: true,
      value: 'jozsef.varga@acrolinx.com',
      groupAnnotations: {},
      autocomplete: 'email',
      readOnly: false,
      name: 'email',
    },
    maxlen: {
      validators: { length: { 'ignore.empty.value': true, min: '1', max: '10' }, 'up-immutable-attribute': {} },
      displayName: 'Max length validator',
      annotations: {},
      required: false,
      groupAnnotations: {},
      readOnly: false,
      name: 'maxlen',
    },
    mailvalid: {
      validators: {
        email: { 'ignore.empty.value': true },
        'up-immutable-attribute': {},
      },
      displayName: 'Email with validator',
      annotations: {},
      required: false,
      groupAnnotations: {},
      readOnly: false,
      name: 'mailvalid',
    },
    req: {
      validators: { 'up-attribute-required-by-metadata-value': {}, 'up-immutable-attribute': {} },
      displayName: 'Required field',
      annotations: {},
      required: true,
      groupAnnotations: {},
      readOnly: false,
      name: 'req',
    },
  },
  attributes: [
    {
      validators: {
        'up-blank-attribute-value': { 'error-message': 'missingEmailMessage', 'fail-on-null': false },
        'up-email-exists-as-username': {},
        length: { max: '255', 'ignore.empty.value': true },
        'up-immutable-attribute': {},
        'up-duplicate-email': {},
        'up-attribute-required-by-metadata-value': {},
        email: { 'ignore.empty.value': true },
        pattern: {
          'ignore.empty.value': true,
          // @ts-expect-error if this isn't undefined, the patter becomes gmail.com coming from keycloakify's kcContextMocks.ts :/
          pattern: undefined,
        },
      },
      displayName: '${email}',
      annotations: {},
      required: true,
      value: 'jozsef.varga@acrolinx.com',
      groupAnnotations: {},
      autocomplete: 'email',
      readOnly: false,
      name: 'email',
    },
    {
      validators: {
        length: { max: '255', 'ignore.empty.value': true },
        'person-name-prohibited-characters': { 'ignore.empty.value': true },
        'up-immutable-attribute': {},
        'up-attribute-required-by-metadata-value': {},
      },
      displayName: '${firstName}',
      annotations: {},
      required: true,
      value: 'Josh',
      groupAnnotations: {},
      readOnly: false,
      name: 'firstName',
    },
    {
      validators: {
        length: { max: '255', 'ignore.empty.value': true },
        'person-name-prohibited-characters': { 'ignore.empty.value': true },
        'up-immutable-attribute': {},
        'up-attribute-required-by-metadata-value': {},
      },
      displayName: '${lastName}',
      annotations: {},
      required: true,
      value: 'Test',
      groupAnnotations: {},
      readOnly: false,
      name: 'lastName',
    },
    {
      validators: {
        length: { 'ignore.empty.value': true, min: '5', max: '255' },
        'up-immutable-attribute': {},
        'up-attribute-required-by-metadata-value': {},
      },
      displayName: 'Test Text Field',
      annotations: { inputHelperTextBefore: 'Whats this' },
      required: true,
      value: 'wefwwef',
      group: 'UMD',
      groupAnnotations: { whats: 'zhis' },
      readOnly: false,
      groupDisplayDescription: 'User metadata',
      name: 'testTextField',
      groupDisplayHeader: 'UMD',
    },
    {
      validators: {
        options: { options: ['Value 1', 'Value 2', 'Value 3', 'Value 4'] },
        'up-immutable-attribute': {},
        'up-attribute-required-by-metadata-value': {},
      },
      displayName: 'Test List',
      annotations: { inputType: 'select', inputHelperTextBefore: 'Placeholder?' },
      required: true,
      // value: 'Value 2',
      group: 'UMD',
      groupAnnotations: { whats: 'zhis' },
      readOnly: false,
      groupDisplayDescription: 'User metadata',
      name: 'testList',
      groupDisplayHeader: 'UMD',
    },
    {
      validators: { uri: { 'ignore.empty.value': true }, 'up-immutable-attribute': {} },
      displayName: 'URL input with validator',
      annotations: {},
      required: false,
      groupAnnotations: {},
      readOnly: false,
      name: 'adasda',
    },
    {
      validators: {
        'username-prohibited-characters': {
          'error-message': 'error-username-invalid-character',
          'ignore.empty.value': true,
        },
        'up-immutable-attribute': {},
      },
      displayName: 'Username with validator',
      annotations: {},
      required: false,
      groupAnnotations: {},
      readOnly: false,
      name: 'usrname2',
    },
    {
      validators: {
        'person-name-prohibited-characters': {
          'error-message': 'error-person-name-invalid-character',
          'ignore.empty.value': true,
        },
        'up-immutable-attribute': {},
      },
      displayName: 'Person name with validator',
      annotations: {},
      required: false,
      groupAnnotations: {},
      readOnly: false,
      name: 'person',
    },
    {
      validators: { length: { 'ignore.empty.value': true, min: '10', max: '20' }, 'up-immutable-attribute': {} },
      displayName: 'Min Length validator',
      annotations: {},
      required: false,
      groupAnnotations: {},
      readOnly: false,
      name: 'length',
    },
    {
      validators: { length: { 'ignore.empty.value': true, min: '1', max: '10' }, 'up-immutable-attribute': {} },
      displayName: 'Max length validator',
      annotations: {},
      required: false,
      groupAnnotations: {},
      readOnly: false,
      name: 'maxlen',
    },
    {
      validators: { 'up-attribute-required-by-metadata-value': {}, 'up-immutable-attribute': {} },
      displayName: 'Required field',
      annotations: {},
      required: true,
      groupAnnotations: {},
      readOnly: false,
      name: 'req',
    },
    {
      validators: {
        email: { 'ignore.empty.value': true },
        'up-immutable-attribute': {},
      },
      displayName: 'Email with validator',
      annotations: {},
      required: false,
      groupAnnotations: {},
      readOnly: false,
      name: 'mailvalid',
    },
    {
      validators: { 'local-date': { 'ignore.empty.value': true }, 'up-immutable-attribute': {} },
      displayName: 'Date with validator',
      annotations: {},
      required: false,
      groupAnnotations: {},
      readOnly: false,
      name: 'date',
    },
    {
      validators: {
        pattern: { pattern: 'asd', 'ignore.empty.value': true, 'error-message': 'error-pattern-no-match' },
        'up-immutable-attribute': {},
      },
      displayName: 'Pattern match',
      annotations: {},
      required: false,
      groupAnnotations: {},
      readOnly: false,
      name: 'pattern',
    },
    {
      validators: { double: { 'ignore.empty.value': true, min: '1', max: '10' }, 'up-immutable-attribute': {} },
      displayName: 'Double number',
      annotations: {},
      required: false,
      groupAnnotations: {},
      readOnly: false,
      name: 'num',
    },
    {
      validators: { integer: { 'ignore.empty.value': true, min: '1', max: '10' }, 'up-immutable-attribute': {} },
      displayName: 'Integer ',
      annotations: {},
      required: false,
      groupAnnotations: {},
      readOnly: false,
      name: 'int',
    },
  ],
};
