export const socialProviers = [
  {
    loginUrl: 'google',
    alias: 'google',
    providerId: 'google',
    displayName: 'Google',
  },
  {
    loginUrl: 'microsoft',
    alias: 'microsoft',
    providerId: 'microsoft',
    displayName: 'Microsoft',
  },
  {
    loginUrl: 'facebook',
    alias: 'facebook',
    providerId: 'facebook',
    displayName: 'Facebook',
  },
  {
    loginUrl: 'instagram',
    alias: 'instagram',
    providerId: 'instagram',
    displayName: 'Instagram',
  },
  {
    loginUrl: 'twitter',
    alias: 'twitter',
    providerId: 'twitter',
    displayName: 'Twitter',
  },
  {
    loginUrl: 'linkedin',
    alias: 'linkedin',
    providerId: 'linkedin',
    displayName: 'LinkedIn',
  },
  {
    loginUrl: 'stackoverflow',
    alias: 'stackoverflow',
    providerId: 'stackoverflow',
    displayName: 'Stackoverflow',
  },
  {
    loginUrl: 'okta',
    alias: 'okta',
    providerId: 'saml',
    displayName: 'Okta',
  },
  {
    loginUrl: 'test',
    alias: 'test',
    providerId: 'test',
    displayName: 'Test',
  },
  {
    loginUrl: 'unknown',
    alias: 'unknown',
    providerId: 'unknown',
    displayName: 'Unknown',
  },
  {
    alias: 'oidc',
    displayName: 'Okta (OIDC)',
    loginUrl: '',
    providerId: 'oidc',
  },
  {
    alias: 'linkedin-openid-connect',
    displayName: 'LinkedIn',
    loginUrl: '',
    providerId: 'linkedin-openid-connect',
  },
];
