import { createRoot } from 'react-dom/client';
import { StrictMode, lazy, Suspense } from 'react';
import { kcContext as kcLoginThemeContext } from './login/kcContext';
import { kcContext as kcAccountThemeContext } from './account/kcContext';
import CssBaseline from '@mui/material/CssBaseline';

const KcLoginThemeApp = lazy(() => import('./login/KcApp'));
const KcAccountThemeApp = lazy(() => import('./account/KcApp'));

createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <Suspense>
      <CssBaseline />
      {(() => {
        if (kcLoginThemeContext !== undefined) {
          return <KcLoginThemeApp kcContext={kcLoginThemeContext} />;
        }

        if (kcAccountThemeContext !== undefined) {
          return <KcAccountThemeApp kcContext={kcAccountThemeContext} />;
        }

        throw new Error("This app is a Keycloak theme. It isn't meant to be deployed outside of Keycloak");
      })()}
    </Suspense>
  </StrictMode>,
);
